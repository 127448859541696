<template>
	<div class="wrap">
		<img class="bg" src="../../assets/img/road/list-bg.png" alt="">
		<div class="title">
			<svg xmlns="http://www.w3.org/2000/svg" width="192.3" height="43.9" viewBox="0 0 192.3 43.9">
				<path id="路径_1541" data-name="路径 1541" d="M-91.5-.85l-4.7-.2.9-27.45,2.5-2.25,2.65.15-.3-8,4.7-.15.3,8.45,2.4.1-.25,4.7-2-.1,1.05,28.1-4.7.2L-90-25.9l-.65-.05Zm34.8-11.1-24.6-.55-2.1-1.85-.55-7.15,2.05-2.25,25.95-.55L-53.8-22l-.75,8.15ZM-77.05-24.6l-2.2-1.9-.25-2.85-1.3.1-.25-4.1,1.25-.1-.25-3.15,2-2.25,19.4-.7,2.15,2.1V-27.3l-2,2.05ZM-64.75-3l8.35,1.95L-57.3,3-71.1-.25l-8.85,3.8-1.7-3.8,3.8-1.65-1.85-.45,1-4,7.2,1.75,5-2.2L-80.7-7l.05-4.2,23.85.4.8,4Zm-11-31.8.1,1.05,11.7-.85.3,4.15-11.7.8.1.8,14.6-.45v-6ZM-61.8-19.95l-1.65,3.75,4.9.1.35-3.95Zm-9.5,3.6,3.4.05,1.55-3.55-5.45.1Zm-8.1-.2,3.9.1-.5-3.2-3.65.05ZM-44.2-.15-46.6-2.6l.55-28.75,2.4-2.3h7.85l2.4,2.15,1.7,28.85-2.35,2.5Zm12-33.45,12.1-.95-1.5-3.35,4.25-2,2.25,4.95,9.55-.8.4,4.7-14.65,1.2-3.15,8,7.65.75,5-3.8,2.9,3.75L-26.9-6.25-29.8-10l9.05-6.9-5.8-.6-2-3.15,3.55-8.8-6.85.55ZM-29.35.2l23.2-16.8,2.75,3.85L-11.8-6.7-6.1.95l-3.75,2.8-5.75-7.7L-26.6,4Zm-7.2-5.05L-37.95-29h-3.4l-.5,24.1ZM4.9-.45l25.5-24.9L7.6-27.4l.45-4.7,12.7,1.15L17.2-36.2l3.9-2.7,5.7,8.45-.05.05,9.15.8,1.45,4L16.45-5.15l7.2,3.25L43.8-7.85l1.3,4.6L24.15,2.85l-1.6-.1L12.8-1.6,8.2,2.9Zm49-23.15-.05-10.65,2.2-2.35,11.8-.8,2.5,2.25.65,11-2.35,2.4-1.1.05.2,8.85,2.3-1.3,2.3,4.1-5.7,3.2-3.55-2L62.8-21.5l-6.45.2Zm17.2-6.15L80-39.9l3.55,3.1L82.4-35.5l11.95,3.7.7,4.15-7.2,5.3L96.1-17.8l-2.25,4.15L83.65-19.3,75.3-13.15,72.5-16.9l6.65-4.9L76.8-23.1l2.3-4.15,4.25,2.4,5.15-3.8-9.45-3-4.4,5.05Zm5.5,33L74.35,1V-9.75l2.15-2.3,14.25-1.6,2.6,2.4-.5,13.1L90.35,4ZM54.1-1.8,56-2.2,54.8-17l4.7-.35,1.15,14.1,11-2.4,1,4.6L55.1,2.75ZM88.25-.75l.3-7.9L79.05-7.6v6.35ZM58.6-26.1l7.5-.25-.3-6.2-7.25.5Z" transform="translate(96.2 39.9)" fill="#ffda00"/>
			</svg>
		</div>
		<ul>
			<li @click="toDetail(item.id)" v-for="(item, index) in list" :key="index">
				<img src="../../assets/img/road/icon.png" alt="">
				<p>{{item.title}}</p>
			</li>
		</ul>
	</div>
</template>

<script>
import { Toast } from 'vant';
import { getRoadList } from '../../common/api/index'
export default {
	data() {
		return {
			list: []
		}
	},
	created () {
		this.findList()

		// 访问记录
		this.$Common._record({openid: this.$store.state.openid, module_id: 6, page: this.$route.path})
	},
	mounted() {
		this.$wxShare._wxConfigJSSDK()
	},
	methods: {
		toDetail (id) {
			this.$router.push('/roadDetail?id=' + id)
		},

		/**
		 * 获取列表数据
		 */
		findList () {
			Toast.loading({
        message: '',
        forbidClick: true,
        duration: 0
      })
			let that = this
			getRoadList({}).then(res => {
				Toast.clear()
				console.log(res)
				if (res.code === 200) {
					that.list = res.data.slow_cough_list
				} else {
					Toast(res.msg)
				}
			})
		}
	},
	components: {
	}
}
</script>

<style scoped lang="scss">
.wrap{
	.bg{
		width: 483px;
		position: fixed;
		bottom: 0;
		left: 0;
	}

	.title{
		margin-top: 89px;

		svg{
			width: 200px;
			display: block;
			margin: auto;
		}
	}

	ul{
		li{
			width: 88%;
			min-height: 130px;
			font-size: 32px;
			color: #FFFFFF;
			margin: auto;
			display: flex;
			align-items: center;
			background: url(../../assets/img/road/list.png) top center no-repeat;
			background-size: 100% 100%;
			margin-top: 48px;
			padding-bottom: 20px;

			img{
				width: 57px;
				margin: 0 30px;
			}

			p{
				margin-right: 30px;
			}

			&:first-child{
				margin-top: 80px;
			}
		}
	}
}
</style>
